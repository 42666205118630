import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #fff;
  z-index: 20;
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 7rem;
  border-bottom: 1px solid grey;

  /* @media only screen and (max-width: 600px) {
    position: relative;
  } */

  img {
    width: 100px;

    @media only screen and (max-width: 600px) {
      width: 80%;
      margin: auto;
    }
  }
`

export const LogoItem = styled.div``

export const LinkSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media only screen and (max-width: 600px) {
    position: absolute;
    right: -20vw;
  }

  a {
    color: #00095b;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    text-decoration: none;

    @media only screen and (max-width: 800px) {
      display: none;
    }
  }
`

export const CloseItem = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;

  img {
    width: 100%;
  }
`
