import styled from 'styled-components'

export const ButtonPrimary = styled.div`
  margin: auto;
  margin-bottom: 25px;
  margin-top: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  a {
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    font-family: FordAntennaCondRegular;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 1px;
    text-transform: none;
    text-align: center;
    padding: 0.5rem 2rem 0.5rem;
    width: ${props => props.width || 'auto'};
    margin-bottom: 0;
    border-radius: 2rem;
    border: 2px solid transparent;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    color: #fff !important;
    background-color: ${props => (props.disabled ? '#6e6e6e' : '#00095b')};
    border-color: ${props => (props.disabled ? '#6e6e6e' : '#00095b')};

    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out,
      border-color 0.3s ease-in-out;

    ${props => props.hasShadow && `box-shadow: 0px 10px 10px #00000029;`}

    &:hover {
      background-color: ${props => (props.disabled ? '#6e6e6e' : '#1700f4')};
      border-color: ${props => (props.disabled ? '#6e6e6e' : '#1700f4')};
      color: #fff;
      transform: ${props => (props.disabled ? 'scale(1)' : 'scale(1.07)')};
    }
  }
`

export const ButtonSecondary = styled.div`
  margin: auto;
  margin-bottom: 25px;
  margin-top: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  a {
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    font-family: FordAntennaCondRegular;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 1px;
    text-transform: none;
    text-align: center;
    padding: 0.5rem 2rem 0.5rem;
    width: ${props => props.width || 'auto'};
    margin-bottom: 0;
    border-radius: 2rem;
    border: 1px solid transparent;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    color: black !important;
    background-color: ${props => (props.disabled ? '#6e6e6e' : 'white')};
    border-color: ${props => (props.disabled ? '#6e6e6e' : '#00095b')};

    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out,
      border-color 0.3s ease-in-out;

    ${props => props.hasShadow && `box-shadow: 0px 10px 10px #00000029;`}

    &:hover {
      background-color: ${props => (props.disabled ? '#6e6e6e' : '#1700f4')};
      border-color: ${props => (props.disabled ? '#6e6e6e' : '#1700f4')};
      color: #fff !important;
      transform: ${props => (props.disabled ? 'scale(1)' : 'scale(1.07)')};
    }
  }
`
