import React from 'react'
import { Template } from '../../../components/template'
import Header from '../../../components/HeaderSearch'
import SearchPage from './SearchPage'

const Search = ({ pageContext, location }) => {
  const {
    metatag_title,
    metatag_description,
    metatag_language,
    has_cookie_consent,
    privacy_policy_subtext,
    privacy_policy_text,
    privacy_policy_accept_button_text
  } = pageContext.website.website_info

  return (
    <Template
      title={metatag_title}
      description={metatag_description}
      language={metatag_language}
      has_cookie_consent={has_cookie_consent}
      site={pageContext.envs.SITE}
      privacy_policy_text={privacy_policy_text}
      privacy_policy_subtext={privacy_policy_subtext}
      privacy_policy_accept_button_text={privacy_policy_accept_button_text}
    >
      <Header header={pageContext.website.header} car={pageContext.car} envs={pageContext.envs} />
      <SearchPage
        searchPage={pageContext.website.search_page}
        features={pageContext.features}
        envs={pageContext.envs}
        model={pageContext.model}
        clusters={pageContext.clusters}
        location={location}
        indexName={pageContext.indexName}
        appId={pageContext.appId}
        searchKey={pageContext.searchKey}
        site={pageContext.envs.SITE}
        basePath={pageContext.basePath}
      />
    </Template>
  )
}

export default Search
