import { useState } from 'react'
import { isMobileOnly, isTablet, isDesktop } from 'react-device-detect'

export const useAutoComplete = (client, model, indexName) => {
  const [suggestions, setSuggestions] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  async function fetchSuggestions({ query }) {
    setLoading(true)
    try {
      const index = await client.initIndex(indexName)
      const data = await index.search(query, {
        analytics: false,
        hitsPerPage: 4,
        restrictSearchableAttributes: ['feature_name'],
        filters: `model_full_name:'${model.Full_Name}'`
      })
      setSuggestions(data.hits)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  return [fetchSuggestions, { suggestions, setSuggestions, error, loading }]
}

export const useSearch = (client, model, indexName) => {
  const [nbHits, setNbHits] = useState()
  const [nbPages, setNbPages] = useState([])
  const [results, setResults] = useState([])
  const [page, setPage] = useState(0)
  const [queryID, setQueryId] = useState()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  const getPlatform = () => {
    if (isMobileOnly) return 'Mobile'
    if (isTablet) return 'Tablet'
    if (isDesktop) return 'Desktop'
  }

  async function fetchResults({ query, currentPage }) {
    setLoading(true)
    try {
      const version = model.Full_Name.replace(model.Model_Name, '').trim()
      const platform = getPlatform()

      const index = await client.initIndex(indexName)
      const data = await index.search(query, {
        hitsPerPage: 4,
        page: currentPage === 0 ? currentPage : currentPage || page,
        filters: `model_full_name:'${model.Full_Name}'`,
        clickAnalytics: true,
        analyticsTags: [model.Full_Name, version, model.Year_Model.toString(), platform]
      })
      setNbHits(data.nbHits)
      setNbPages(data.nbPages)
      setQueryId(data.queryID)
      setResults(data.hits)
    } catch (error) {
      console.log(error)
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const handlePageChange = async (query, action) => {
    let currentPage = page

    if (action === 'next') {
      if (page < nbPages - 1) {
        currentPage++
      }
    } else if (action === 'previus') {
      if (page >= 1) {
        currentPage--
      }
    }
    setPage(currentPage)
    await fetchResults({ query, currentPage })
  }

  return [fetchResults, { results, nbHits, page, setPage, handlePageChange, nbPages, queryID, error, loading }]
}
