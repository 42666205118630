import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Container } from './styles'
import { Link, navigate } from 'gatsby'
import { useAutoComplete, useSearch } from './hooks'
import { ButtonPrimary } from '../../../../components/Buttons/style'
import aa from 'search-insights'
import { useAlgoliaClient } from '../../../../services/search'
import slugify from 'slugify'

export const SearchBox = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 15px 20px;
  padding-top: 75px;

  @media only screen and (max-width: 600px) {
    align-items: initial;
    padding-top: 10px;
  }
`

export const SearchTitle = styled.div`
  h6 {
    @media only screen and (max-width: 600px) {
      font-size: 1.2rem;
      margin: 10px 0px !important;
    }
  }
`

export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;

  @media only screen and (max-width: 600px) {
    display: block;
  }
`

export const InputContainer = styled.div`
  border: 1px solid #6e6e6e;
  border-radius: 3px 3px 0px 0px;
  font-weight: 600;
  margin-right: 30px;
  transition-duration: 0.2s;
  width: 100%;

  &:hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px 3px 0px 0px;
  }

  @media only screen and (max-width: 600px) {
    margin-bottom: 30px;
    margin-right: 0px !important;
  }
`
export const InputComponent = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    padding: 12px;
    max-width: 25px;
  }

  input {
    border: 0px;
    width: 100%;
    color: #00095b;
    font-size: 1.1rem;
    font-weight: 500;
    font-family: 'FordAntenna';

    ::-webkit-input-placeholder {
      font-family: 'FordAntenna';
    }

    :-ms-input-placeholder {
      font-family: 'FordAntenna';
    }

    :-moz-placeholder {
      font-family: 'FordAntenna';
    }

    ::-moz-placeholder {
      font-family: 'FordAntenna';
    }
  }
`

export const AutoCompleteContainer = styled.div`
  font-weight: 500;
  position: absolute;
  background: #fff;
  border: 1px solid #6e6e6e;
  border-radius: 0px 0px 3px 3px;
  border-top: 0px;
  margin: 0px 0px 0px -1px;
  box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.2);
  z-index: 9;
  width: ${props => props.width - 2 + 'px'};
  text-align: start;
`

export const AutoCompleteItem = styled.div`
  padding: 0px 20px;
  cursor: pointer;

  p {
    font-size: 0.9rem;
  }

  &:hover {
    background: #6e6e6e;

    p {
      color: #fff;
    }
  }
`

export const ButtonSecondary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #00095b;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    font-family: 'Ford Antenna';

    &:hover {
      color: #1700f4;
      text-decoration: underline;
    }
  }
`

export const ResultContainer = styled.div`
  border-top: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  width: 100%;
  padding: 25px 0px;
`

export const ResultItem = styled.div`
  margin-bottom: 30px;

  a {
    &:hover {
      text-decoration: underline;
    }
  }
`

export const NotFoundContainer = styled.div`
  display: flex;
  color: red;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;

  p {
    color: red;
    font-size: 0.7rem;
    margin-left: 20px;
  }
`

const SearchPage = ({ clusters, model, indexName, location, searchPage, appId, searchKey, basePath, car, envs }) => {
  const APP_ID = appId
  const SEARCH_API_KEY = searchKey
  const { client } = useAlgoliaClient({ APP_ID, SEARCH_API_KEY })

  const [searchValue, setSearchValue] = useState()
  const [modelFullName, setModelFullName] = useState()
  const [showSuggestions, setShowSuggestions] = useState(true)
  const [fetchSuggestions, { suggestions }] = useAutoComplete(client, model, indexName)
  const [fetchResults, { results, nbHits, page, setPage, nbPages, handlePageChange, queryID }] = useSearch(
    client,
    model,
    indexName
  )
  const [width, setWidth] = useState(0)
  const InputRef = useRef(null)

  const _basePath = basePath ? `/${basePath}` : ''

  useEffect(() => {
    setWidth(InputRef.current.getBoundingClientRect().width)
  }, [searchValue])

  useEffect(() => {
    aa('init', {
      appId: APP_ID,
      apiKey: SEARCH_API_KEY
    })

    let modelFullName = document.location.href.slice(1).replace(/-/g, ' ')
    const pos = modelFullName.indexOf('/')
    if (pos > 0) {
      modelFullName = modelFullName.slice(0, pos)
    }
    setModelFullName(modelFullName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (searchValue && modelFullName && showSuggestions) {
      // setShowSuggestions(true)
      fetchSuggestions({ query: searchValue })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  useEffect(() => {
    if (searchValue && !showSuggestions) {
      setPage(0)
      fetchResults({ query: searchValue })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSuggestions])

  function search(e) {
    if (window.gtag && car) {
      window.gtag('event', 'search-bar', {
        event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
        event_label: window.location.href
      })
    }

    e.preventDefault()
    setPage(0)
    fetchResults({ query: searchValue })
    setShowSuggestions(false)
  }

  function autoCompleteItemOnClick(hit) {
    setSearchValue(hit.feature_name)
    setShowSuggestions(false)
  }

  function parseClusterName(cluster_name) {
    const name = cluster_name.slice(1, cluster_name.length).toLowerCase()
    return cluster_name[0] + name
  }

  function clickEvent({ objectID, objectPosition }) {
    aa('clickedObjectIDsAfterSearch', {
      eventName: 'click_on_feature',
      index: indexName,
      userToken: 'user-1',
      queryID,
      objectIDs: [objectID],
      positions: [objectPosition + 1 + 4 * page]
    })
  }

  function handlePageClick(event) {
    if (event.target.localName === 'input') {
      event.stopPropagation()
      return
    } else {
      setShowSuggestions(false)
    }
  }

  return (
    <Container onClick={event => handlePageClick(event)}>
      <div style={{ margin: '0 auto', paddingBottom: '30px' }}>
        <SearchBox>
          <SearchTitle>
            <h6 style={{ margin: '20px 0 10px 0', padding: 0 }}>{searchPage.title_search}</h6>
          </SearchTitle>
          <form onSubmit={search} style={{ width: '100%' }}>
            <div style={{ textAlign: 'start', marginBottom: '30px', width: '100%' }}>
              <p style={{ fontSize: '0.7rem', color: 'gray', opacity: !searchValue && '0', transitionDuration: '0.3s' }}>
                {searchPage.search_input_label}
              </p>
              <SearchContainer>
                <InputContainer ref={InputRef}>
                  <InputComponent>
                    <div>
                      <span style={{ width: '50px' }} className="fds-icon fds-font--ford-icons__search"></span>
                    </div>
                    <input
                      placeholder={searchPage.search_input_label}
                      value={searchValue}
                      autoFocus={true}
                      onChange={event => setSearchValue(event.target.value)}
                      onFocus={() => setShowSuggestions(true)}
                    />
                    <div>
                      <span
                        style={{ width: '50px', cursor: 'pointer', opacity: !searchValue && '0', transitionDuration: '0.3s' }}
                        className="fds-icon fds-font--ford-icons__clear"
                        onClick={() => setSearchValue('')}
                      ></span>
                    </div>
                  </InputComponent>
                  <AutoCompleteContainer
                    width={width}
                    style={{ display: !showSuggestions && 'none', border: !showSuggestions && '0px' }}
                  >
                    {searchValue &&
                      suggestions.map(
                        hit =>
                          searchValue !== hit.feature_name && (
                            <AutoCompleteItem onClick={() => autoCompleteItemOnClick(hit)}>
                              <p>{hit.feature_name}</p>
                            </AutoCompleteItem>
                          )
                      )}
                  </AutoCompleteContainer>
                </InputContainer>
                <button type="submit">
                  <ButtonPrimary disabled={!searchValue} style={{ margin: '0px' }} onClick={search}>
                    <a>{searchPage.button_text}</a>
                  </ButtonPrimary>
                </button>
              </SearchContainer>
            </div>
          </form>
          {nbHits >= 0 && (
            <ResultContainer>
              {results.length > 0 ? (
                <>
                  <p style={{ fontSize: '1rem', fontWeight: '500', marginBottom: '20px' }}>
                    {nbHits} {searchPage.result_text}
                    {nbHits > 1 ? 's' : ''}:
                  </p>
                  {results &&
                    results.map((result, index) => (
                      <ResultItem key={index}>
                        <p style={{ fontSize: '0.65rem', fontWeight: '500', lineHeight: '1.6rem' }}>
                          {result.cluster_name.toUpperCase()}
                        </p>
                        <p style={{ fontSize: '0.9rem', color: '#6e6e6e', fontWeight: '500' }}>{result.feature_name}</p>
                        <div style={{ display: 'flex' }}>
                          <Link
                            to={result.feature_url + `?callback=${location.href}`}
                            onClick={() => {
                              if (window.gtag && car) {
                                window.gtag('event', 'search-see-details', {
                                  event_category: `${car[
                                    envs.CATEGORY
                                  ].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
                                  event_label: window.location.href
                                })
                              }

                              clickEvent({ objectID: result.objectID, objectPosition: index })
                            }}
                            style={{ fontSize: '0.9rem', cursor: 'pointer' }}
                          >
                            {searchPage.see_feature_text_button}
                          </Link>
                          <span
                            className="fds-icon"
                            style={{
                              display: 'block',
                              transform: 'rotate(180deg)',
                              marginLeft: '10px',
                              alignItems: 'center',
                              alignSelf: 'center'
                            }}
                          >
                            <span className="fds-font--ford-icons__chevron-filled-left-path1"></span>
                            <span className="fds-font--ford-icons__chevron-filled-left-path2"></span>
                          </span>
                        </div>
                      </ResultItem>
                    ))}
                </>
              ) : (
                <>
                  <NotFoundContainer>
                    <span className="fds-icon fds-font--ford-icons__error"></span>
                    <p>{searchPage.no_results_found_text}</p>
                  </NotFoundContainer>
                  <p style={{ fontSize: '1rem', fontWeight: '500', marginBottom: '20px' }}>{searchPage.sugestions_text}</p>
                  {clusters.map(cluster => (
                    <ResultItem>
                      <p style={{ fontSize: '0.9rem', color: '#6e6e6e', fontWeight: '500' }}>
                        {parseClusterName(cluster.Cluster_Name)}
                      </p>
                      <div style={{ display: 'flex' }}>
                        <Link
                          to={`${_basePath}/${slugify(
                            `${model.Year_Model} ${model.Full_Name.toLowerCase().replace(`${model.Year_Model}`, '')}`
                          )}/characteristics?char=${cluster.Cluster_Name}&callback=${location.href}`}
                          style={{ fontSize: '0.9rem', cursor: 'pointer' }}
                          onClick={() => {
                            if (window.gtag && car) {
                              window.gtag('event', 'search-see-details', {
                                event_category: `${car[
                                  envs.CATEGORY
                                ].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
                                event_label: window.location.href
                              })
                            }
                          }}
                        >
                          {searchPage.see_feature_text_button}
                        </Link>
                        <span
                          className="fds-icon"
                          style={{
                            display: 'block',
                            transform: 'rotate(180deg)',
                            marginLeft: '10px',
                            alignItems: 'center',
                            alignSelf: 'center'
                          }}
                        >
                          <span className="fds-font--ford-icons__chevron-filled-left-path1"></span>
                          <span className="fds-font--ford-icons__chevron-filled-left-path2"></span>
                        </span>
                      </div>
                    </ResultItem>
                  ))}
                </>
              )}
            </ResultContainer>
          )}
          {nbPages > 1 && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span className="fds-icon">
                  <span className={`fds-font--ford-icons__chevron-left pagination ${page + 1 === 1 && 'disabled'}`}></span>
                </span>
                <p
                  style={{
                    fontSize: '0.9rem',
                    cursor: page + 1 !== 1 && 'pointer',
                    color: page + 1 === 1 && '#6e6e6e',
                    marginLeft: '7px'
                  }}
                  onClick={() => handlePageChange(searchValue, 'previus')}
                >
                  {searchPage.back_text_button}
                </p>
              </div>
              <p style={{ fontSize: '0.9rem', margin: '0px 40px' }}>
                {page + 1} {searchPage.of_text} {nbPages}
              </p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p
                  style={{
                    fontSize: '0.9rem',
                    cursor: page + 1 !== nbPages && 'pointer',
                    color: page + 1 === nbPages && '#6e6e6e',
                    marginRight: '7px'
                  }}
                  onClick={() => handlePageChange(searchValue, 'next')}
                >
                  {searchPage.next_text_button}
                </p>
                <span className="fds-icon">
                  <span
                    className={`fds-font--ford-icons__chevron-right pagination ${page + 1 === nbPages && 'disabled'}`}
                  ></span>
                </span>
              </div>
            </div>
          )}
        </SearchBox>

        <div style={{ textAlign: 'center' }}>
          <p
            style={{ fontSize: '0.8rem', cursor: 'pointer', width: '90px', margin: 'auto' }}
            onClick={() => {
              if (window.gtag && car) {
                window.gtag('event', 'search-cancel', {
                  event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
                  event_label: window.location.href
                })
              }
              navigate(-1)
            }}
          >
            {searchPage.cancel_text}
          </p>
        </div>
      </div>
    </Container>
  )
}

export default SearchPage
