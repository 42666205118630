import React from 'react'
import { HeaderWrapper, Container, LogoItem, LinkSearch, SearchItem } from './styles'

import { Link, navigate } from 'gatsby'

import Logo from '../../assets/images/logo.svg'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

const Header = ({ header, car, envs }) => {
  const breakpoints = useBreakpoint()

  return (
    <Container>
      <HeaderWrapper>
        <div className="content">
          {breakpoints.md && <div></div>}
          <LogoItem>
            <a href={header.logo_link || '/'}>
              <img src={header.logo?.url ? `https://www.ford360guide.com${header.logo?.url}` : Logo} alt="" />
            </a>
          </LogoItem>
          <LinkSearch>
            <SearchItem>
              <span
                onClick={() => {
                  if (window.gtag && car) {
                    window.gtag('event', 'search-cancel', {
                      event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
                      event_label: window.location.href
                    })
                  }

                  navigate(-1)
                }}
                className="fds-icon fds-font--ford-icons__clear"
              ></span>
            </SearchItem>
          </LinkSearch>
        </div>
      </HeaderWrapper>
    </Container>
  )
}

export default Header
