import styled from 'styled-components'

export const Container = styled.div`
  /* padding: 20px 100px;
  position: fixed;
  width: 100vw; */
  // position: fixed;
  max-width: 1440px;
  width: 100%;
  background-color: #fff;
  z-index: 9;
`

export const HeaderWrapper = styled.div`
  height: 80px !important;
  padding: 0 100px;

  .content {
    display: flex;
    padding: 20px 0px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #00095b;

    @media only screen and (max-width: 1024px) {
      padding: 40px 0px;
      justify-content: center;
    }

    @media only screen and (max-width: 600px) {
      padding: 25px 0px;
    }
  }

  img {
    height: 36px;
    @media only screen and (max-width: 1024px) {
      height: 38px;
    }
  }

  img {
    width: 95px;

    @media only screen and (max-width: 600px) {
      /* width: 80%; */
      /* margin: auto; */
    }

    @media only screen and (max-width: 768px) {
      /* width: 80%; */
      width: 102px;
    }
  }

  @media (max-width: 1024px) {
    padding: 0 75px;
  }

  @media only screen and (max-width: 600px) {
    /* width: 80%; */
    margin: auto;
    padding: 0 20px;
  }

  /* @media only screen and (min-width: 1200px) {
    justify-content: flex-start;
  } */
`

export const LogoItem = styled.div`
  /* width: 75%; */

  @media (max-width: 1024px) {
    img {
      display: flex;
      margin: auto;
    }
  }

  @media (max-width: 600px) {
    position: absolute;
    margin: -20px;
    top: 40px;
    margin-left: 0px;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    width: 100vw;
    position: absolute;
    margin-left: 0px;
    img {
      display: flex;
      margin: auto;
    }
  }
`

export const LinkSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media only screen and (max-width: 600px) {
    // position: absolute;
    // right: -20vw;
    margin-left: auto;
  }

  a {
    color: #00095b;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    text-decoration: none;

    @media only screen and (max-width: 800px) {
      display: none;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    position: absolute;
    right: 75px;
  }
`

export const SearchItem = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;

  img {
    width: 100%;
  }

  span {
    font-size: 1.4rem !important;
  }
`
